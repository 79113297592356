import { useStaticQuery, graphql } from "gatsby";

const useEvents = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryEvents {
        allContentfulEvents {
          nodes {
            title
            date
            text {
              text
            }
            image {
              gatsbyImageData(height: 262)
            }
            slug
            childContentfulEventsBodyTextNode {
              childrenMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `
  );
  return data.allContentfulEvents.nodes.map((i) => ({
    title: i.title,
    heading: i.date,
    body: i.text.text,
    image: i.image.gatsbyImageData,
    slug: i.slug,
    article: i.childContentfulEventsBodyTextNode,
  }));
};

export default useEvents;
