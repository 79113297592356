import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../layout/Layout";

import useEvents from "../utils/useEvents";

export default function EventPost({ pageContext, data }) {
  const dataEvents = useEvents();
  const { slug } = pageContext;
  const slugString = JSON.stringify(slug);

  const dataFiltered = dataEvents.filter(
    (i) => JSON.stringify(i.slug) === slugString
  );
  console.log(data);
  const dataBody = data.allContentfulEventsBodyTextNode.nodes.map(
    (i) => i.childrenMarkdownRemark[0].html
  );

  return (
    <Layout>
      {dataFiltered.map((i) => (
        <div
          key={i.id}
          className="flex flex-col items-center mx-auto gap-6 py-6 md:py-12"
        >
          {/* <p className="navbar opacity-50 text-center">
            {i.date.split("T")[0]}
          </p> */}
          <h1 className="mobile-title lg:desktop-title text-center">
            {i.title}
          </h1>
          <div
            className="mobile-paragraph lg:dektop-paragraph text-center prose max-w-5xl pt-4 px-4 lg:px-8"
            dangerouslySetInnerHTML={{
              __html: dataBody,
            }}
          />
          <Link
            to="/events"
            className="cards bg-secondary  text-white rounded-lg px-5 py-3"
          >
            Back to Events
          </Link>
        </div>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query MyQueryEvents2 {
    allContentfulEventsBodyTextNode {
      nodes {
        childrenMarkdownRemark {
          html
        }
      }
    }
  }
`;
